@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  src: local('OpenSans Regular'), local('OpenSans-Regular'), url('../fonts/OpenSans-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/Roboto-Bold.ttf') format('truetype');
}

@import '../../../node_modules/normalize.css/normalize.css';

body,
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body { cursor: default; }

a,
button,
img {
  border: none;
  outline: none;
  text-decoration: none;
  background: none;
  color: inherit;
}

img {
  image-orientation: none
}

/* -- common styles for links it text -- */
a { color: rgb(0, 0, 0); cursor: pointer; }
a:hover { color: rgb(128, 180, 171); }

input::-ms-clear {
  display: none;
}

input[type=text],
input[type=date] {
  border-radius: 0; 
  -webkit-appearance: inherit;
}

/* fix react-images */
.ReactModal__Overlay--after-open {
  height: 100%;
}
.react-images__track {
  /*transition: transform .1s;  ie11 bug*/
}
main.react-images__pager {
  max-width: 100vw;
}
.react-images__container button:focus,
.react-images__container a:focus { outline: none !important; }
.react-images__view {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 100vh;
  height: 100vh;
}
.react-images__view img {
  max-height: calc(100vh - 210px);
  max-width: calc(100vw - 180px);
}
@media only screen and (max-width: 768px) {
  .react-images__view img {
    max-width: calc(100vw - 30px);
  }
}
div[data-focus-on-hidden="true"] {
  pointer-events: auto !important;
}